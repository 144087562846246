import { getLanguage, t } from 'react-switch-lang';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../styles/LandingPages.module.scss';
import testimonials from '../paysimply-testimonials.json';
import TestimonialCard from './TestimonialCard';
import Link from './Link';
import { events, logAmpEvent } from '../utils/Amplitude';
import RenderMarkdown from './RenderMarkdown';

export default function TestimonialSlideShow({ pageAmpProps }) {
  const slideShowRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const updateScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = slideShowRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  };

  const lang = getLanguage();

  const currLangTestimonials =
    testimonials?.filter((testimonial) => testimonial.language === lang);

  const sideScroll = (direction) => {
    const { clientWidth } = slideShowRef.current;
    logAmpEvent(events.USER_CLICKED_TESTIMONIALS_SLIDESHOW_BUTTON, {
      Page: pageAmpProps,
      Direction: direction,
    });

    /*
      The testimonial card has a width of 400px on large viewports and 300px on smaller viewports.
      The list has 20px of gap.
      So, using the following scroll amounts to scroll exactly one testimonial card each time.
    */
    const scrollAmount = clientWidth > 767.5 ? 420 : 320;
    const scrollBy = {
      left: direction === 'right' ? scrollAmount : -scrollAmount,
      behavior: 'smooth',
    };
    slideShowRef.current.scrollBy(scrollBy);
  };

  useEffect(() => {
    const currentSlideShowRef = slideShowRef.current;
    currentSlideShowRef.addEventListener('scroll', updateScrollButtons);
    return () => {
      currentSlideShowRef.removeEventListener('scroll', updateScrollButtons);
    };
  }, []);

  return (
    <section className={`${styles.sectionContainer} pt-1`}>
      <Container fluid="xxl" className={styles.slideShowContainer}>
        <div className={styles.headingContainer}>
          <h2>{t('Testimonials_Heading_Title')}</h2>
          <p>
            <RenderMarkdown markdown={t('Testimonials_Heading_SubTitle')} />
          </p>
        </div>
        <div className={styles.testimonialsContainer} ref={slideShowRef}>
          {currLangTestimonials.map((testimonial, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.testimonialCardContainer}
            >
              <TestimonialCard
                rating={testimonial.rating}
                paymentOption={testimonial.payment_option}
                type={testimonial.type}
                testimonial={testimonial.testimonial}
              />
            </div>
          ))}
        </div>
        <div className={styles.slideShowControlsContainer}>
          <div className={styles.slideShowControls}>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="leftButtonTooltip">
                  {t('Testimonials_SlideShow_Left_ButtonToolTip')}
                </Tooltip>
              )}
            >
              <button
                type="button"
                onClick={() => sideScroll('left')}
                className={styles.slideShowLeftButton}
                disabled={!canScrollLeft}
                aria-label={t('Testimonials_SlideShow_Left_ButtonDescription')}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="lg"
                  color={!canScrollLeft ? '#696969' : '#2b4e7b'}
                />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="leftButtonTooltip">
                  {t('Testimonials_SlideShow_Right_ButtonToolTip')}
                </Tooltip>
             )}
            >
              <button
                type="button"
                onClick={() => sideScroll('right')}
                className={styles.slideShowRightButton}
                disabled={!canScrollRight}
                aria-label={t('Testimonials_SlideShow_Right_ButtonDescription')}
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="lg"
                  color={!canScrollRight ? '#696969' : '#2b4e7b'}
                />
              </button>
            </OverlayTrigger>
          </div>
          <div className={styles.testimonialsViewMoreLink}>
            <Link
              href="/testimonials"
              text={t('Testimonials_SlideShow_LinkText')}
              aria-label={t('Testimonials_SlideShow_LinkDescription')}
              ampEvent={events.USER_CLICKED_TESTIMONIALS_VIEW_MORE_LINK}
              ampEventProps={{ Page: pageAmpProps }}
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
