import { Col, Container, Row } from 'react-bootstrap';
import { getLanguage, t } from 'react-switch-lang';
import Image from 'next/image';
import styles from '../styles/LandingPages.module.scss';
import { ExternalLink } from './Link';
import { events } from '../utils/Amplitude';
import { ReplaceSuperScript } from './ReplaceTextWithElement';

export default function PaymentSourceSection({ pageAmpProps }) {
  const lang = getLanguage();
  const awardsImageSrc = `/images/logos/Awards/${lang === 'fr' ? 'BestManagedCompanies-fr.png' : 'BestManagedCompanies.png'}`;
  return (
    <section className={styles.sectionContainer}>
      <Container fluid="xxl">
        <Row className="justify-content-center mb-4">
          <Col md={10} xs={12}>
            <div className={styles.paymentSourceTitleContainer}>
              <h2 className={styles.paymentSourceTitle}>
                <ReplaceSuperScript
                  text={t('PaymentSource_Section_Title')}
                />
              </h2>
              <Image
                src="/images/logos/PSI.png"
                alt={t('PSI_Logo_ImageDescription')}
                width={150}
                height={60}
              />
            </div>
            <p>
              {t('PaymentSource_Section_Description')}
              {' '}
              <ExternalLink
                href={t('Link_PaymentSource')}
                text={t('PaymentSource_LearnMore_LinkText')}
                aria-label={t('PaymentSource_LearnMore_LinkDescription')}
                ampEvent={events.USER_CLICKED_PAYMENTSOURCE_LEARN_MORE_LINK}
                ampEventProps={{ Page: pageAmpProps }}
              />
            </p>
          </Col>
          <Col md={10} xs={12} className={styles.awardsContainer}>
            <div>
              <Image
                src={awardsImageSrc}
                alt={t('BestManagedCompanyAward_ImageDescription')}
                width={130}
                height={45}
              />
              <p>{t('PaymentSource_Awards_Description1')}</p>
            </div>
            <div>
              <Image
                src="/images/logos/Awards/LoadhubAward.png"
                alt={t('LoadHub_CashInnovationAward_ImageDescription')}
                width={50}
                height={45}
              />
              <p>{t('PaymentSource_Awards_Description2')}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
