import Image from 'next/image';
import { t } from 'react-switch-lang';
import { Col, Container, Row } from 'react-bootstrap';
import styles from '../styles/LandingPages.module.scss';
import ReplaceTextWithElement from './ReplaceTextWithElement';

function PaymentOptionImages({ images, height }) {
  return (
    <div className={styles.poImagesContainer}>
      {images?.map(({ path, alt }) => (
        <Image
          height={0}
          width={0}
          src={path}
          key={path}
          alt={t(alt)}
          style={{ objectFit: 'contain', width: 'auto', height, margin: '3px' }}
          sizes="(max-width: 768px) 100vw, 50vw"
        />
      ))}
    </div>
  );
}

/**
 * PaymentOptionsList component renders a list of payment options for display purposes.
 * Each payment option includes images, a description, and a fee.
 * Component also includes an optional disclaimer text.
 *
 * @param {object} props
 * @param {boolean} [props.isCRALandingPage=false] Flag to determine if the component
 * is being used on the CRA landing page or not. If true,
 * the disclaimer text will not be displayed and fee for Credit will be different.
 * @param {string[]} [props.hidePaymentOptions=[]] List of payment options to hide
 */
export default function PaymentOptionsList({ isCRALandingPage = false, hidePaymentOptions = [] }) {
  const poData = {
    Credit: {
      height: 28,
      images: [
        {
          path: '/images/logos/CreditCards/Visa.png',
          alt: 'Visa_ImageDescription',
        },
        {
          path: '/images/logos/CreditCards/Mastercard.png',
          alt: 'Mastercard_ImageDescription',
        },
        {
          path: '/images/logos/CreditCards/Amex.png',
          alt: 'Amex_ImageDescription',
        },
        {
          path: '/images/logos/CreditCards/UnionPay.png',
          alt: 'UnionPay_ImageDescription',
        },
      ],
      description: 'MainLanding_PaymentOption_Credit_Description',
      fee: isCRALandingPage ? 'CRALanding_PaymentOption_Credit_Fee' : 'MainLanding_PaymentOption_Credit_Fee',
    },
    InPerson: {
      height: 30,
      images: [
        {
          path: '/images/logos/CanadaPost.png',
          alt: 'AvailableAtCanadaPostLogo_ImageDescription',
        },
      ],
      description: 'MainLanding_PaymentOption_InPerson_Description',
      fee: 'MainLanding_PaymentOption_InPerson_Fee',
    },
    eTransfer: {
      height: 36,
      images: [
        {
          path: '/images/logos/eTransfer.png',
          alt: 'ETransfer_ImageDescription',
        },
      ],
      description: 'MainLanding_PaymentOption_ETransfer_Description',
      fee: 'MainLanding_PaymentOption_ETransfer_Fee',
    },
    PayPal: {
      height: 28,
      images: [
        {
          path: '/images/logos/PayPal.png',
          alt: 'PayPalLogo_ImageDescription',
        },
      ],
      description: 'MainLanding_PaymentOption_PayPal_Description',
      fee: 'MainLanding_PaymentOption_PayPal_Fee',
    },
  };
  return (
    <>
      <section className={styles.paymentOptionsSection}>
        <Container fluid="xxl">
          <Row className={`${styles.paymentOptionsList}`}>
            {Object.entries(poData).map(([key, value]) => (
              !hidePaymentOptions.includes(key) && (
                <Col lg={3} xs={6} key={key} className={styles.paymentOptionCard}>
                  <PaymentOptionImages
                    images={value.images}
                    height={value.height}
                  />
                  <p className={styles.paymentOptionDescription}>
                    <ReplaceTextWithElement
                      text={t(value.description)}
                      replaceWith={<i>Interac</i>}
                      replaceKey="Interac"
                    />
                  </p>
                  <span className={styles.feeChip}>{t(value.fee)}</span>
                </Col>
              )
            ))}
          </Row>
        </Container>
      </section>
      {!isCRALandingPage && <p className={styles.paymentOptionsDisclaimer}>{t('MainLanding_PaymentOption_Disclaimer')}</p>}
    </>

  );
}
